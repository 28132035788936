import RATINGS1516 from "../data/ratings.1516.json";
import RATINGS1617 from "../data/ratings.1617.json";
import RATINGS1718 from "../data/ratings.1718.json";
import RATINGS1819 from "../data/ratings.1819.json";
import RATINGS1920 from "../data/ratings.1920.json";
import RATINGS2021 from "../data/ratings.2021.json";
import RATINGS2122 from "../data/ratings.2122.json";
import RATINGS2223 from "../data/ratings.2223.json";
import RATINGS2324 from "../data/ratings.2324.json";
import RATINGS2425 from "../data/ratings.2425.json";
import RECORDS1516 from "../data/records.1516.json";
import RECORDS1617 from "../data/records.1617.json";
import RECORDS1718 from "../data/records.1718.json";
import RECORDS1819 from "../data/records.1819.json";
import RECORDS1920 from "../data/records.1920.json";
import RECORDS2021 from "../data/records.2021.json";
import RECORDS2122 from "../data/records.2122.json";
import RECORDS2223 from "../data/records.2223.json";
import RECORDS2324 from "../data/records.2324.json";
import RECORDS2425 from "../data/records.2425.json";
import TEAMS from "../data/teams.json";

/*
    Retrieve a specific team from the master list of team data
        (conference, home venues)
*/
export const Team = (team_code) => {
  if (team_code === "OMA") {
    team_code = "NEBOM";
  }
  let team = TEAMS.find(t=>t['team_code']===team_code);

  if (team === undefined) {
    team = TEAMS.find(t=>t['school']===team_code);
    if (team === undefined) {
      console.log("team not found: "+team_code);
      return null;
    }
  }
  return team;
};

/*
    Retreive a specific season's ratings
*/
export const SeasonRatings=(season_code)=> {
  let r = RATINGS2324;
  switch(season_code) {
    case "2425": r=RATINGS2425; break;
    case "2324": r=RATINGS2324; break;
    case "2223": r=RATINGS2223; break;
    case "2122": r=RATINGS2122; break;
    case "2021": r=RATINGS2021; break;
    case "1920": r=RATINGS1920; break;
    case "1819": r=RATINGS1819; break;
    case "1718": r=RATINGS1718; break;
    case "1617": r=RATINGS1617; break;
    case "1516": r=RATINGS1516; break;
    default: r=RATINGS2324;
  }
  return r;
};

/*
    Retrieve a specific season's records
*/
export const SeasonRecords=(season_code)=>{
  let r = RECORDS2324;
  switch(season_code) {
    case "2425": r=RECORDS2425; break;
    case "2324": r=RECORDS2324; break;
    case "2223": r=RECORDS2223; break;
    case "2122": r=RECORDS2122; break;
    case "2021": r=RECORDS2021; break;
    case "1920": r=RECORDS1920; break;
    case "1819": r=RECORDS1819; break;
    case "1718": r=RECORDS1718; break;
    case "1617": r=RECORDS1617; break;
    case "1516": r=RECORDS1516; break;
    default: r=RECORDS2324;
  }
  return r['teams'];
};

/*
    Retrieve the record for the specified school 
        in the specified season
*/
export const TeamRecord=(team_code,season_code)=>{
  if (team_code === "OMA") {
    team_code = "NEBOM";
  }
  const all_records=SeasonRecords(season_code);
  const defaultRecord = {'W': 0, 'L': 0}
  const defaultFullRecord={'overall': defaultRecord, 'home': defaultRecord, 'away': defaultRecord, 'neutral': defaultRecord, 'conference': defaultRecord}
  if (Object.keys(all_records).includes(team_code) === false) {
    console.log("No record found for "+team_code);
    return defaultFullRecord;
  }
  return all_records[team_code];
};

export const HomeEdge=(season_code)=>{
  const all_ratings=SeasonRatings(season_code);
  return all_ratings['home_edge']
}
export const TeamRating=(team_code,season_code)=>{
  if (team_code === "OMA") {
    team_code = "NEBOM";
  }
  const all_ratings=SeasonRatings(season_code)['teams'];
  const defaultRating=0.0;
  let team_rating = all_ratings.find(r=>r['team_code']===team_code);
  if (team_rating===undefined) {
    console.log("No rating found for "+team_code);
    return defaultRating;
  }
  return team_rating['rating'];
}